<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <FilterRegional
                    class="mr-2 mt-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
                <FilterProvince
                    class="mr-2 mt-2"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
                <FilterArea
                    class="mr-2 mt-2"
                    v-can:hide.distributor
                    v-model:value="state.params.area"
                    v-model:provinsi="state.params.provinsi"/>
                <FilterKabupatenKota
                    class="mr-2 mt-2"
                    v-can:hide.distributor
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:area="state.params.area"/>
                <FilterDistributor
                    class="mr-2 mt-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"
                    v-model:distributor="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:provinsi="state.params.provinsi"
                    v-model:kabupaten="state.params.kabupaten"/>
                <FilterToko
                    class="mr-2 mt-2"
                    v-model:value="state.params.toko"/>
                <AMonthPicker
                    v-has-role="[ROLE_ADMIN_BK, ROLE_HELPDESK, ROLE_TSO, ROLE_ASM, ROLE_SPC_GROUP]"
                    class="mr-2 mt-2"
                    v-model:value="state.params.periode"
                    placeholder="Pilih Periode"
                    style="width:300px"
                    format="MMMM YYYY"/>
                <ASelect
                    v-has-role="[ROLE_ADMIN_BK, ROLE_HELPDESK, ROLE_ASM, ROLE_SPC_GROUP]"
                    class="mr-2 mt-2"
                    v-model:value="state.params.status_level_1"
                    show-search
                    allow-clear
                    placeholder="Pilih Status Level 1"
                    style="width: 300px"
                    :options="state.statusLevel1Lists"/>
                <ASelect
                    v-has-role="[ROLE_ADMIN_BK, ROLE_HELPDESK, ROLE_ASM, ROLE_SPC_GROUP]"
                    class="mr-2 mt-2"
                    v-model:value="state.params.status_level_2"
                    show-search
                    allow-clear
                    placeholder="Pilih Status Level 2"
                    style="width: 300px"
                    :options="state.statusLevel2Lists"/>
                <AButton
                    class="mr-2 mt-2"
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
          <div class="col-lg-6 col-md-12"></div>
          <div class="col-lg-6 col-md-12 text-right">
            <a-button
                title="download excel"
                type="primary"
                @click="btnDownloadExcel"
                :loading="state.isDownloading">
                <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.isDownloading"></i>
                <span v-else>Downloading ...</span>
            </a-button>
          </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
                <template #action="{ record }">
                    <span>
                        <ATooltip title="Detail History">
                            <button
                                type="button"
                                @click="btnDetail(record.details)"
                                class="btn btn-sm btn-success"
                                shape="round"
                                size="small">
                                <i class="fe fe-info fa-lg" />&nbsp;Detail
                            </button>
                        </ATooltip>
                    </span>
                </template>
            </MdTable>
        </div>

        <!-- details by id -->
        <DetailHistory
            v-if="visibleDetailModal"
            v-model:visible="visibleDetailModal"
            v-model:items="visibleDetailItemsModal"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterToko from '@/components/filter/FilterToko'
import FilterDistributor from '@/components/filter/FilterDistributor'
import DetailHistory from './DetailHistory'
import { pickBy, includes } from 'lodash'
import {
    ROLE_ADMIN_BK,
    ROLE_ASM,
    ROLE_TSO,
    ROLE_SPC_GROUP,
    ROLE_HELPDESK,
    hasRoles,
} from '@/helpers'
import moment from 'moment'

export default defineComponent({
    components: {
        FilterProvince,
        FilterKabupatenKota,
        FilterRegional,
        FilterArea,
        FilterToko,
        FilterDistributor,
        DetailHistory,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Region',
                    dataIndex: 'regional',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Alamat',
                    dataIndex: 'address',
                },
                {
                    title: 'Area',
                    dataIndex: 'area',
                },
                {
                    title: 'Kota',
                    dataIndex: 'city',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'province',
                },
                {
                    title: 'Mapping Distributor',
                    dataIndex: 'distributor_mapping',
                },
                {
                    title: 'SSM',
                    dataIndex: 'user_ssm',
                },
                {
                    title: 'ASM',
                    dataIndex: 'user_sm',
                },
                {
                    title: 'TSO',
                    dataIndex: 'user_am',
                },
                {
                    title: 'Status',
                    children: [
                        {
                            title: 'Level 1',
                            dataIndex: 'approve_lv1',
                        },
                        {
                            title: 'Level 2',
                            dataIndex: 'approve_lv2',
                        },
                    ],
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: {
                q: '',
                region: [],
                provinsi: [],
                area: [],
                kabupaten: [],
                toko: [],
                status_level_1: null,
                status_level_2: null,
                periode: moment(new Date()).startOf('month').format('YYYY-MM'),
                distributor: [],
                page: 1,
                "per-page": 10,
            },
            statusLevel1Lists: [
                {
                    label: "Approve level 1 (tso)",
                    value: 2,
                },
                {
                    label: "Reject level 1 (tso)",
                    value: 3,
                },
                {
                    label: "Delete TSO",
                    value: 6,
                },
            ],
            statusLevel2Lists: [
                {
                    label: "Approve level 2 (asm)",
                    value: 4,
                },
                {
                    label: "Reject level 2 (asm)",
                    value: 5,
                },
                {
                    label: "Delete Approve ASM",
                    value: 7,
                },
                {
                    label: "Delete Reject ASM",
                    value: 8,
                },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.periode) {
                params.periode = moment(state.params.periode).format('YYYY-MM')
            }

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get('/api/report-lelang/history-approval', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    
                    items.forEach((item, i) => {
                        item.key = i
                        item['progress_download'] = false
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // download export excel
        const btnDownloadExcel = () => {
            state.isDownloading = true
            apiClient
                .get('/api/report-lelang/history-approval', {
                    params: {
                        ...queryParams(),
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `History-Toko-Lelang_${moment().format('DDMMYY_HHmm')}.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.isDownloading = false
                })
        }

        // handle detail by item
        const visibleDetailModal = ref(false);
        const visibleDetailItemsModal = ref(null);

        const btnDetail = (items) => {
            visibleDetailModal.value = true
            visibleDetailItemsModal.value = items
        }

        // handle vue
        onMounted(() => {
            fetchDataList()

            // validation list status for a ASM role
            if (hasRoles([ROLE_ASM])) {
                state.statusLists = state.statusLists.filter(item => {
                    if (item.value == 2 || item.value == 3) {
                        return item
                    }
                })
            }
        })

        return {
            fetchDataList,
            state,
            // rowSelection,
            handleTableChange,
            btnDownloadExcel,
            errorMessage,
            ROLE_ADMIN_BK,
            ROLE_HELPDESK,
            ROLE_ASM,
            ROLE_TSO,
            ROLE_SPC_GROUP,
            btnDetail,
            visibleDetailModal,
            visibleDetailItemsModal,
        }
    },
})
</script>
